import { useState } from "react";
import hr from "dayjs/locale/hr";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
//import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

import "./CustomDate.scss";

const handleFocus = (event) => event.target.select();

const CustomInput = (props) => {
  return (
    <Input
      borderColor="gray.600"
      onFocus={(e) => {
        handleFocus(e);
        props.setFocus(true);
      }}
      onBlur={() => props.setFocus(false)}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const CustomDate = (props) => {
  //const [selectedDate, setDate] = React.useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setFocus] = useState(false);
  const materialTheme = createMuiTheme({
    /*   overrides: {
      MuiPickersDay: {
        day: {
          color: "gray",
          backgroundColor: true ? red["300"] : "blue",
        },
        daySelected: {
          backgroundColor: lightBlue["400"],
        },
        dayDisabled: {
          color: lightBlue["100"],
        },
        current: {
          color: lightBlue["900"],
        },
      },
    }, */
  });
  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider
        className="datepicker"
        utils={DayJsUtils}
        locale={hr}
      >
        <InputGroup>
          <KeyboardDatePicker
            {...props}
            open={isOpen}
            isFocused={isFocused}
            setFocus={setFocus}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            format="DD.MM.YYYY"
            TextFieldComponent={CustomInput}
          />
          <InputRightElement
            children={
              <CalendarIcon
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
                color="green.500"
              />
            }
          />
        </InputGroup>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
export default CustomDate;
