import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../functions/handleRequests";
import jwt_decode from "jwt-decode";

if (sessionStorage.getItem("currentUser") === "") {
  sessionStorage.removeItem("currentUser");
}
const currentUserSubject = new BehaviorSubject(
  JSON.parse(sessionStorage.getItem("currentUser"))
);
export const authenticationService = {
  login,
  logout,
  setUser,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function setUser(user) {
  sessionStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
  return user;
}
function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${process.env.REACT_APP_HOST_URL}/users/login`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      const decoded = jwt_decode(res);

      const user = {
        id: decoded.id,
        username: decoded.username,
        email: decoded.email,
        lokacija: decoded.lokacija,
        token: res,
      };
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      setUser(user);
      return user;
    })
    .catch((error) => {
      return { error };
    });
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
