import { useState } from "react";
import { Link } from "react-router-dom";
import { authenticationService } from "../../services/authentication";
import { useHistory } from "react-router-dom";
import {
  Box,
  Heading,
  Flex,
  Button,
  Text,
  Input,
  Container,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const Label = ({ children, required }) => {
  return (
    <FormLabel>
      {children}{" "}
      {required ? (
        <Text as="span" color="red.500">
          *
        </Text>
      ) : null}
    </FormLabel>
  );
};

const Login = () => {
  let history = useHistory();
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, errors, handleSubmit } = useForm({
    defaultValues: { username: "", password: "" },
  });

  const submit = (data) => {
    setIsSubmitting(true);
    const { username, password } = data;
    authenticationService
      .login(username, password)
      .then((res) => {
        if (res.error) {
          toast({
            position: "bottom",
            title: "Greška",
            description: res.error,
            status: "error",
            isClosable: true,
          });
          setTimeout(() => {
            toast.closeAll();
            setIsSubmitting(false);
          }, 2500);
        } else {
          setIsSubmitting(false);
          history.push("/admin");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box maxW="1280px" mx="auto" mt="12" px="4">
      <Flex>
        <Heading as="h1" my="6" size="xl">
          Prijava
        </Heading>
        <Button as={Link} to="/" ml="auto" size="sm">
          Natrag
        </Button>
      </Flex>
      <Container maxW="xl">
        <Flex
          as="form"
          justify="center"
          p="8"
          borderRadius="10px"
          flexWrap="wrap"
          boxShadow="rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px"
          onSubmit={handleSubmit(submit)}
          bgColor="gray.100"
        >
          <FormControl id="username" isInvalid={errors.username}>
            <Label required>Korisničko ime</Label>
            <Controller
              bgColor="white"
              borderColor="gray.500"
              control={control}
              name="username"
              as={<Input type="text" />}
              rules={{ required: "Korisničko ime je obvezno polje" }}
            />
            <ErrorMessage
              as={FormErrorMessage}
              errors={errors}
              name="username"
            />
          </FormControl>
          <FormControl id="password" isInvalid={errors.password} mt="6">
            <Label required>Lozinka</Label>
            <Controller
              bgColor="white"
              borderColor="gray.500"
              control={control}
              name="password"
              as={<Input type="password" />}
              rules={{ required: "Lozinka je obvezno polje" }}
            />
            <ErrorMessage
              as={FormErrorMessage}
              errors={errors}
              name="password"
            />
          </FormControl>
          <Button
            isLoading={isSubmitting}
            loadingText="Slanje"
            type="submit"
            mt="4"
            colorScheme="green"
            ml="auto"
          >
            Prijava
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

export default Login;
