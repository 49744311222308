import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { createStore, StateMachineProvider } from "little-state-machine";
import { Box, Heading, Flex, Button, Text } from "@chakra-ui/react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
//import { generateDates } from "../../functions/tools";
import Appointment from "../Appointment/Appointment";
import Form from "../Form/Form";
import LastStep from "./LastStep";

createStore({
  data: {
    lokacija: "",
    datum: null,
    termin: "",
    terminiLength: 0,
    OIB: "",
    OIBTvrtke: "",
    datumRodenja: new Date(),
    drzava: "",
    email: "",
    grad: "",
    ime: "",
    nazivTvrtke: "",
    postanskiBroj: "",
    prezime: "",
    telefon: "",
    ulica: "",
    zupanija: "",
  },
});

function getSteps() {
  return ["Korak 1", "Korak 2", "Korak 3"];
}
function getStepContent(
  step,

  handleBack,
  handleNext,
  activeStep,
  steps
) {
  switch (step) {
    case 0:
      return (
        <Appointment
          handleBack={handleBack}
          handleNext={handleNext}
          activeStep={activeStep}
          steps={steps}
        />
      );
    case 1:
      return (
        <Form
          handleBack={handleBack}
          handleNext={handleNext}
          activeStep={activeStep}
          steps={steps}
        />
      );
    case 2:
      return <LastStep />;
    default:
      return "Unknown step";
  }
}

const Main = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);
  return (
    <Box maxW="1280px" mx="auto" mt="12" px="4">
      <Flex flexDir={{ base: "column", md: "row" }}>
        <Heading as="h1" my="6" size="xl">
          Narudžba za testiranje na Koronavirus
        </Heading>
        <Button
          order={{ base: -1, md: 2 }}
          as={Link}
          to="/login"
          ml="auto"
          size="sm"
        >
          Admin prijava
        </Button>
      </Flex>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Text>All steps completed - you&apos;re finished</Text>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Flex>
              <StateMachineProvider>
                {getStepContent(
                  activeStep,
                  handleBack,
                  handleNext,
                  activeStep,
                  steps
                )}
              </StateMachineProvider>
            </Flex>
          </div>
        )}
      </div>
    </Box>
  );
};

export default Main;
