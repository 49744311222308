import { useWindowSize } from "../../functions/hooks";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Heading,
  Flex,
  Button,
  Text,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { handlePostRequest } from "../../functions/handleRequests";
import { authenticationService } from "../../services/authentication";
import * as dayjs from "dayjs";
import "./AdminDashboard.scss";

function getTermin(date, termin) {
  const time = termin.split(":");
  return dayjs(date)
    .hour(time[0])
    .minute(time[1])
    .second(0)
    .format("DD.MM.YYYY HH:mm");
}

const closeTermin = (id, setFetch, onClose) => {
  const currentUser = authenticationService.currentUserValue;

  handlePostRequest(
    `${process.env.REACT_APP_HOST_URL}/${currentUser.lokacija}/admin/close`,
    [
      {
        id: id,
      },
    ],
    currentUser.token
  )
    .then((res) => {
      if (res.data === "Termin zatvoren") {
        setFetch(Math.random() * Math.random());
        onClose(false);
      }
    })
    .catch((err) => console.log(err));
};

const AdminDashboard = () => {
  let history = useHistory();
  const size = useWindowSize();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState({});
  const [fetch, setFetch] = useState(0);

  const getEvents = useCallback(
    async (dates, successCallback, failureCallback) => {
      try {
        const currentUser = authenticationService.currentUserValue;

        const res = await handlePostRequest(
          `${process.env.REACT_APP_HOST_URL}/${currentUser.lokacija}/admin/get-date`,
          [
            {
              month: dates.month,
              year: dates.year,
              date: dates.date,
              dateCheck: dayjs().hour(6).minute(0),
            },
          ],
          currentUser.token
        );
        const data = res.data.map((item) => {
          return {
            date: new Date(item.datum),
            title: `${item.ime} ${item.prezime}`,
            data: item,
          };
        });

        successCallback(data);
      } catch {
        console.log();
      }
    },
    []
  );

  const handleClick = (e) => {
    setData(e.event.extendedProps.data);
    onOpen(true);
  };

  function logout() {
    authenticationService.logout();
    history.push("/login");
  }
  return (
    <Box maxW="1280px" mx="auto" mt="12" px="4">
      <Flex>
        <Heading as="h1" mb="6" size="xl">
          Pregled narudžbi
        </Heading>
        <Button ml="auto" size="sm" onClick={logout}>
          Odjava
        </Button>
      </Flex>
      <Container maxW="7xl">
        <FullCalendar
          locale="hr"
          dayMaxEventRows={7}
          initialView={size < 700 ? "timeGrid" : ""}
          dayCount={1}
          moreLinkContent={(e) => `+${e.num} više`}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          buttonText={{ today: "danas" }}
          eventClassNames="eventClass"
          allDaySlot={false}
          eventClick={(e) => {
            e.jsEvent.preventDefault();
            handleClick(e);
          }}
          events={useCallback(
            (fetchInfo, successCallback, failureCallback) => {
              const date = new Date(
                dayjs(fetchInfo.start).add(1, "week").format()
              );
              const month = date.getMonth() + 1;
              const year = date.getFullYear();
              getEvents(
                { month, year, date: fetchInfo.start },
                successCallback,
                failureCallback,
                fetch
              );
            },
            [getEvents, fetch]
          )}
        />
      </Container>
      {data.id && (
        <Modal
          size="xl"
          initialFocusRef={null}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Pregled termina</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <UnorderedList styleType="none">
                <ListItem>
                  <Text
                    as="span"
                    fontSize={{ base: "1em", md: "1.3em" }}
                    fontWeight="500"
                  >
                    Ime:{" "}
                  </Text>
                  <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                    {data.ime}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    as="span"
                    fontSize={{ base: "1em", md: "1.3em" }}
                    fontWeight="500"
                  >
                    Prezime:{" "}
                  </Text>
                  <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                    {data.prezime}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    as="span"
                    fontSize={{ base: "1em", md: "1.3em" }}
                    fontWeight="500"
                  >
                    Datum rođenja:{" "}
                  </Text>
                  <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                    {dayjs(data.datumRodenja).format("DD.MM.YYYY")}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    as="span"
                    fontSize={{ base: "1em", md: "1.3em" }}
                    fontWeight="500"
                  >
                    Dob:{" "}
                  </Text>
                  <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                    {Math.abs(
                      dayjs(data.datumRodenja).diff(dayjs(new Date()), "year")
                    )}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    as="span"
                    fontSize={{ base: "1em", md: "1.3em" }}
                    fontWeight="500"
                  >
                    Adresa:{" "}
                  </Text>
                  <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                    {data.ulica}, {data.postanskiBroj} {data.grad}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    as="span"
                    fontSize={{ base: "1em", md: "1.3em" }}
                    fontWeight="500"
                  >
                    OIB:{" "}
                  </Text>
                  <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                    {data.OIB}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    as="span"
                    fontSize={{ base: "1em", md: "1.3em" }}
                    fontWeight="500"
                  >
                    Email:{" "}
                  </Text>
                  <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                    {data.email}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    as="span"
                    fontSize={{ base: "1em", md: "1.3em" }}
                    fontWeight="500"
                  >
                    Termin:{" "}
                  </Text>
                  <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                    {getTermin(data.datum, data.termin)}
                  </Text>
                </ListItem>
                {data.nazivTvrtke && data.OIBTvrtke && (
                  <>
                    <ListItem>
                      <Text
                        as="span"
                        fontSize={{ base: "1em", md: "1.3em" }}
                        fontWeight="500"
                      >
                        Naziv tvrtke:{" "}
                      </Text>
                      <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                        {data.nazivTvrtke}
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text
                        as="span"
                        fontSize={{ base: "1em", md: "1.3em" }}
                        fontWeight="500"
                      >
                        OIB tvrtke:{" "}
                      </Text>
                      <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                        {data.OIBTvrtke}
                      </Text>
                    </ListItem>
                  </>
                )}
              </UnorderedList>
            </ModalBody>

            <ModalFooter>
              <Button
                mr="5"
                colorScheme="green"
                onClick={() => {
                  closeTermin(data.id, setFetch, onClose);
                }}
              >
                Zatvori termin
              </Button>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Odustani
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default AdminDashboard;
