import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery } from "react-query";
import { handlePostRequest } from "../../functions/handleRequests";
import {
  Container,
  Grid,
  GridItem,
  Box,
  Flex,
  Heading,
  Spinner,
  Button,
} from "@chakra-ui/react";
import Times from "./Times";
import { generateDates } from "../../functions/tools";
import updateAction from "../updateAction";
import { useStateMachine } from "little-state-machine";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Appointment.scss";
import * as dayjs from "dayjs";

const options = [
  { value: "bjelovar", label: "BJELOVAR, JOSIPA JELAČIĆA 13 C" },
  { value: "daruvar", label: "DARUVAR, PETRA PRERADOVIĆA 7" },
];

const Appointment = ({ handleNext, handleBack, activeStep, steps }) => {
  const { action, state } = useStateMachine(updateAction);
  const [daysFlag, setDaysFlag] = useState();
  const [terminFlag, setTerminFlag] = useState(0);

  const { control, getValues, setValue, watch, register } = useForm({
    defaultValues: { ...state.data },
  });

  useEffect(() => {
    register("termin");
    register("lokacija");
    register("datum");
  }, [register]);

  const scrollTo = useRef(null);
  const executeScroll = () => scrollTo.current.scrollIntoView();

  //function below will get all dates that are disabled
  const { isFetching, data } = useQuery(
    ["ambulanteData", daysFlag],
    () =>
      handlePostRequest(`${process.env.REACT_APP_HOST_URL}/booked/get-date`, [
        { datum: new Date(2020, 11, 23), lokacija: "bjelovar" },
      ]).then((res) => {
        // console.log(getValues().lokacija.value);
        /*  setdata(
              ? [2, 4, 0]
              : [0, 2, 3, 5, 6]
          ); */
        const data = res.data.map((item) => {
          return new Date(item.datum).getTime();
        });
        return data;
      }),
    { enabled: daysFlag }
  );

  //function below will get actual termins for date
  const { isFetching: tFetching, data: tData } = useQuery(
    ["terminiData", terminFlag],
    () =>
      handlePostRequest(
        `${process.env.REACT_APP_HOST_URL}/${
          getValues().lokacijaNoSave.value
        }/get-date`,
        [
          {
            datumTermina: dayjs(getValues().datum)
              .hour(0)
              .minute(0)
              .second(0)
              .format(),
            lokacija: getValues().lokacija.value,
          },
        ]
      ).then((res) => {
        const data = res.data.map((item) => {
          return {
            ...item,
            datum: new Date(item.datum),
            datumRodenja: new Date(item.datumRodenja),
            datumTermina: new Date(item.datumTermina),
          };
        });
        const intersection = generateDates(getValues().datum).filter(function (
          e
        ) {
          return (
            data
              .map((item) => {
                return item.datum;
              })
              .map(Number)
              .indexOf(+e.date) === -1
          );
        });
        executeScroll();
        return intersection;
      }),
    { enabled: terminFlag }
  );
  const notWorking =
    getValues().lokacija && getValues().lokacija.value === "bjelovar"
      ? [2, 4, 0]
      : [0, 2, 3, 5, 6];

  return (
    <Container maxW="7xl" px={{ base: 0, lg: "1em" }}>
      <Heading as="h3" size="md" my="2">
        Odabir ambulante
      </Heading>
      <Flex my="2">
        <Controller
          control={control}
          name="lokacijaNoSave"
          render={({ onChange, onBlur, value, name, ref }) => (
            <Box
              as={Select}
              onBlur={onBlur}
              value={value}
              name={name}
              w="350px"
              placeholder="Odaberi"
              options={options}
              onChange={(e) => {
                action({ ...state.data, datum: null, lokacija: e });
                setValue("lokacija", e);
                setValue("datum", null);
                setValue("termin", "");
                onChange(e);
                setTerminFlag(0);
                setDaysFlag(Math.random() * Math.random() + Math.random());
              }}
            />
          )}
        />
      </Flex>
      <Flex justifyContent="center">
        <Spinner
          d={isFetching ? "inline-block" : "none"}
          size="xl"
          thickness="3px"
          color="blue.500"
        />
      </Flex>
      <Grid
        d={data && !isFetching ? "grid" : "none"}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={4}
        className="dateContainer"
      >
        <GridItem colSpan={1}>
          <Heading as="h5" size="md" color="black" ml="4" pt="2">
            Odaberi datum
          </Heading>
          {!isFetching && data && (
            <Flex justifyContent="center" mt="6">
              <Controller
                name="datumNoSave"
                control={control}
                render={({ ref, onChange, ...rest }) => {
                  return (
                    <Calendar
                      locale="hr-HR"
                      className="calendar"
                      next2Label={null}
                      prev2Label={null}
                      tileDisabled={({ activeStartDate, date, view }) => {
                        if (view === "month") {
                          return (
                            notWorking.includes(date.getDay()) ||
                            date.getTime() < new Date().getTime() ||
                            data.includes(date.getTime())
                          );
                        } else if (view === "year") {
                          return date.getMonth() < new Date().getMonth();
                        }
                      }}
                      onChange={(date) => {
                        onChange(date);
                        setValue("termin", "");
                        setValue("datum", date);
                        action({ ...state.data, datum: date });
                        setTerminFlag(
                          Math.random() * Math.random() + Math.random()
                        );
                      }}
                      {...rest}
                    />
                  );
                }}
              />
            </Flex>
          )}
        </GridItem>
        <GridItem mt={{ base: 6, md: 0 }} colSpan={1}>
          {tData && !tFetching && terminFlag > 0 && getValues().datumNoSave ? (
            <Box
              w="100%"
              h="100%"
              bgColor="blue.600"
              borderRadius="md"
              boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
            >
              <Times
                values={tData}
                watch={watch(["termin"])}
                setValue={setValue}
                getValues={getValues}
                ref={scrollTo}
              ></Times>
            </Box>
          ) : (
            <Flex
              w="100%"
              h={{ base: 250, md: "100%" }}
              bgColor="blue.400"
              borderRadius="md"
              boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
              justifyContent="center"
              ref={scrollTo}
            >
              <Heading
                alignSelf="center"
                color="white"
                as="h5"
                size="lg"
                textAlign="center"
              >
                Odaberi datum za prikaz termina
              </Heading>
            </Flex>
          )}
        </GridItem>
      </Grid>
      <Flex justifyContent="flex-end" my="4">
        <Button mr="2" disabled={activeStep === 0} onClick={handleBack}>
          Natrag
        </Button>

        <Button
          disabled={
            tData && !tFetching && terminFlag > 0 && getValues().termin
              ? false
              : true
          }
          colorScheme="green"
          onClick={() => {
            const values = getValues();
            delete values.lokacijaNoSave;
            delete values.datumNoSave;
            action({ ...values, terminiLength: tData.length });
            handleNext();
          }}
        >
          {activeStep === steps.length - 1 ? "Završi" : "Dalje"}
        </Button>
      </Flex>
    </Container>
  );
};
export default Appointment;
