import Main from "../Main/Main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ScrollToTop } from "../../functions/hooks";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Login from "../Login/Login";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import Footer from "./Footer";

function App() {
  return (
    <>
      <div className="content">
        <Router>
          <ScrollToTop />

          <div>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              <PrivateRoute path="/admin">
                <AdminDashboard />
              </PrivateRoute>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/">
                <Main />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
