import { Box, Button, Heading } from "@chakra-ui/react";

const Times = ({ values, getValues, setValue, ref }) => {
  //console.log(values);
  return (
    <>
      <Heading as="h5" size="md" color="white" ml="4" pt="2">
        Odaberi termin
      </Heading>
      <Box p="4" ref={ref}>
        {values.length > 0 &&
          values.map((item) => {
            return (
              <Button
                key={item.time}
                transform={
                  getValues().termin === item.time ? "scale(1.1)" : null
                }
                w="85px"
                m="1"
                onClick={() => {
                  setValue("termin", item.time);
                }}
                bgColor={
                  getValues().termin === item.time
                    ? "gray.300"
                    : "rgba(0,0,0, 0.5)"
                }
                color={getValues().termin === item.time ? "gray.700" : "white"}
                _hover={{ color: "black", bgColor: "gray.300" }}
              >
                {item.time}
              </Button>
            );
          })}
      </Box>
    </>
  );
};

export default Times;
