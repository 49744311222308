//import * as dayjs from "dayjs";

export const generateDates = (date) => {
  const arr = [];
  let currentH = 7;
  let currentM = 30;
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  //7:30 - 9:30
  do {
    const time = `0${currentH}:${currentM < 10 ? "0" + currentM : currentM}`;
    arr.push({
      date: new Date(year, month, day, currentH, currentM),
      time: time,
    });
    currentM += 5;
    if (currentM === 60) {
      currentM = 0;
      currentH++;
    }
    if (currentH === 9 && currentM >= 35) {
      break;
    }
  } while (currentH >= 7 && currentH < 10);

  return arr;
};
/* 
var intersection = [].filter(function (e) {
  return [].map(Number).indexOf(+e.datumTermina) > -1;
});
 */