import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Heading,
  Container,
  Button,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import updateAction from "../updateAction";
import { useStateMachine } from "little-state-machine";
import NumberFormat from "react-number-format";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CustomDate from "../CustomDate";
import DayJsUtils from "@date-io/dayjs";
import * as dayjs from "dayjs";
import hr from "dayjs/locale/hr";
import { handlePostRequest } from "../../functions/handleRequests";
import { useState } from "react";

const Error = ({ children }) => {
  return (
    <Text color="red.500" my="1">
      {children}
    </Text>
  );
};
const Label = ({ children, required }) => {
  return (
    <FormLabel>
      {children}{" "}
      {required ? (
        <Text as="span" color="red.500">
          *
        </Text>
      ) : null}
    </FormLabel>
  );
};
const emailRegEx = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const Podaci = ({
  activeStep,
  steps,
  handleNext,
  handleBack,
  generatedDates,
}) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, getValues, errors, handleSubmit } = useForm({
    defaultValues: state.data,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const submit = (data) => {
    setIsSubmitting(true);
    console.log(data);
    const time = state.data.termin.split(":");
    data.datum = dayjs(state.data.datum)
      .hour(time[0])
      .minute(time[1])
      .second(0)
      .format();
    data.datumTermina = dayjs(data.datum).hour(0).minute(0).second(0).format();
    data.lokacija = state.data.lokacija.value;

    if (state.data.terminiLength === 1) {
      handlePostRequest(`${process.env.REACT_APP_HOST_URL}/booked/add`, [
        {
          datum: data.datumTermina,
          termin: state.data.termin,
          lokacija: state.data.lokacija.value,
          filled: true,
        },
      ]).then((res) => {
        //console.log(res);
      });
    }
    handlePostRequest(
      `${process.env.REACT_APP_HOST_URL}/${state.data.lokacija.value}/add`,
      [{ ...state.data, ...data }]
    ).then((res) => {
      if (res.data.error) {
        toast({
          position: "bottom",
          title: "Greška",
          description: res.data.error,
          status: "error",
          isClosable: true,
        });
        setIsSubmitting(false);
        setTimeout(() => {
          toast.closeAll();
          handleBack();
        }, 3000);
      } else {
        setIsSubmitting(false);
        handleNext();
      }
      console.log(res);
    });

    action(getValues());
  };
  return (
    <Box mx="auto">
      <Heading as="h2" size="lg" mb="4">
        Unos podataka
      </Heading>
      <Container maxW="3xl">
        <form onSubmit={handleSubmit(submit)}>
          <Flex flexWrap="wrap">
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="ime" isInvalid={errors.ime}>
                <Label required={true}>Ime</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="ime"
                  as={<Input type="text" />}
                  rules={{ required: "Ime je obvezno polje" }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="ime" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="prezime">
                <Label required={true}>Prezime</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="prezime"
                  as={<Input type="text" />}
                  rules={{ required: "Prezime je obvezno polje" }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="prezime" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="nazivTvrtke">
                <Label>
                  Naziv tvrtke (samo za one koji imaju potpisan ugovor o
                  testiranju s HZJZ)
                </Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="nazivTvrtke"
                  as={<Input type="text" />}
                />
                <ErrorMessage
                  as={<Error />}
                  errors={errors}
                  name="nazivTvrtke"
                />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="OIBTvrtke">
                <Label>
                  OIB tvrtke (samo za one koji imaju potpisan ugovor o
                  testiranju s HZJZ)
                </Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="OIBTvrtke"
                  maxLength={11}
                  as={
                    <NumberFormat
                      allowLeadingZeros
                      decimalSeparator={false}
                      type="text"
                      customInput={Input}
                    />
                  }
                  rules={{
                    minLength: {
                      value: 11,
                      message: "OIB tvrtke mora sadržavati najmanje 11 znakova",
                    },
                  }}
                />

                <ErrorMessage as={<Error />} errors={errors} name="OIBTvrtke" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="OIB">
                <Label required={true}>OIB</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="OIB"
                  maxLength={11}
                  as={
                    <NumberFormat
                      allowLeadingZeros
                      decimalSeparator={false}
                      type="text"
                      customInput={Input}
                    />
                  }
                  rules={{
                    required: "OIB je obvezno polje",
                    minLength: {
                      value: 11,
                      message: "OIB mora sadržavati najmanje 11 znakova",
                    },
                  }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="OIB" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="datumRodenja">
                <Label required={true}>Datum rođenja</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="datumRodenja"
                  render={({ value, onChange, ...rest }) => {
                    return (
                      <MuiPickersUtilsProvider locale={hr} utils={DayJsUtils}>
                        <CustomDate
                          autoOk
                          value={value}
                          onChange={(date) => {
                            onChange(date);
                          }}
                          {...rest}
                        />
                      </MuiPickersUtilsProvider>
                    );
                  }}
                  rules={{ required: "Datum rođenja je obvezno polje" }}
                />
                <ErrorMessage
                  as={<Error />}
                  errors={errors}
                  name="datumRodenja"
                />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="drzava">
                <Label required={true}>Država</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="drzava"
                  as={<Input type="text" />}
                  rules={{ required: "Država je obvezno polje" }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="drzava" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="ulica">
                <Label required={true}>Ulica</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="ulica"
                  as={<Input type="text" />}
                  rules={{ required: "Ulica je obvezno polje" }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="ulica" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="grad">
                <Label required={true}>Grad</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="grad"
                  as={<Input type="text" />}
                  rules={{ required: "Grad je obvezno polje" }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="grad" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="zupanija">
                <Label required={true}>Županija</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="zupanija"
                  as={<Input type="text" />}
                  rules={{ required: "Županija je obvezno polje" }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="zupanija" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="postanskiBroj">
                <Label required={true}>Poštanski broj</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="postanskiBroj"
                  maxLength="5"
                  as={
                    <NumberFormat
                      allowLeadingZeros
                      decimalSeparator={false}
                      type="text"
                      customInput={Input}
                    />
                  }
                  rules={{
                    required: "Poštanski broj je obvezno polje",
                    minLength: {
                      value: 5,
                      message:
                        "Poštanski broj mora sadržavati najmanje 5 znamenki",
                    },
                  }}
                />
                <ErrorMessage
                  as={<Error />}
                  errors={errors}
                  name="postanskiBroj"
                />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="telefon">
                <Label required={true}>Telefon</Label>

                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="telefon"
                  as={
                    <NumberFormat
                      allowLeadingZeros
                      decimalSeparator={false}
                      type="text"
                      customInput={Input}
                    />
                  }
                  rules={{ required: "Telefon je obvezno polje" }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="telefon" />
              </FormControl>
            </Box>
            <Box
              w={{ base: "100%", md: 1 / 2 }}
              px={{ base: "4" }}
              mt={{ base: 2 }}
            >
              <FormControl id="email">
                <Label required={true}>Email</Label>
                <Controller
                  borderColor="gray.600"
                  control={control}
                  name="email"
                  as={<Input />}
                  rules={{
                    required: "Email je obvezno polje",
                    pattern: {
                      value: emailRegEx,
                      message: "Email adresa nije ispravna",
                    },
                  }}
                />
                <ErrorMessage as={<Error />} errors={errors} name="email" />
              </FormControl>
            </Box>
          </Flex>

          <Flex justifyContent="flex-end" my="4">
            <Button mr="2" disabled={isSubmitting} onClick={handleBack}>
              Natrag
            </Button>

            <Button
              type="submit"
              colorScheme="green"
              isLoading={isSubmitting}
              loadingText="Slanje"
            >
              {activeStep === steps.length - 1 ? "Završi" : "Pošalji"}
            </Button>
          </Flex>
        </form>
      </Container>
    </Box>
  );
};

export default Podaci;
