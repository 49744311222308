import { Box } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box w="100%" mt="10" className="footer" maxW="1280px" mx="auto">
      <Box>&copy; 2020</Box>
    </Box>
  );
};
export default Footer;
