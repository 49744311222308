import {
  Flex,
  Box,
  Heading,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useStateMachine } from "little-state-machine";
import * as dayjs from "dayjs";

const ambulante = {
  bjelovar: "BJELOVAR, JOSIPA JELAČIĆA 13 C",
  daruvar: "DARUVAR, PETRA PRERADOVIĆA 7",
};

function getTermin(date, termin) {
  const time = termin.split(":");
  return dayjs(date)
    .hour(time[0])
    .minute(time[1])
    .second(0)
    .format("DD.MM.YYYY HH:mm");
}

const LastStep = () => {
  const { state } = useStateMachine();
  const { isOpen, onOpen, onClose } = useDisclosure();
  console.log(state.data);

  return (
    <Flex justify="center" flexWrap="wrap" mx="auto">
      <Flex maxW="950px" mx="auto" flexDir="column" justify="center">
        <Heading size={"xl"} ml="10px" mx="auto" mb={4}>
          Termin zabilježen
        </Heading>
        <Box
          as={CheckCircleIcon}
          fill="green.500"
          color="green.500"
          mx="auto"
          style={{ width: "10rem", height: "10rem" }}
        />
        <Text textAlign="center" fontSize="1.2em" fontWeight="500" my="6">
          Klikni ispod za pregled svojeg termina
        </Text>
      </Flex>

      <Flex
        w="100%"
        justify="space-between"
        flexWrap="wrap"
        maxW="900px"
        mx="auto"
        mt="15px"
        justifyContent="center"
      >
        <Button onClick={onOpen}>Pregled termina</Button>
      </Flex>
      <Flex maxW="950px" mx="auto" flexDir="column">
        <Text textAlign="center" mt="20" color="blue.600" fontSize="18px">
          Izrada:{" "}
          <Box
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            textDecor="underline"
            href="http://parpar.hr"
          >
            parpar.hr
          </Box>
        </Text>
      </Flex>
      <Modal size="xl" initialFocusRef={null} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pregled termina</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UnorderedList styleType="none">
              <ListItem>
                <Text
                  as="span"
                  fontSize={{ base: "1em", md: "1.3em" }}
                  fontWeight="500"
                >
                  Ime:{" "}
                </Text>
                <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                  {state.data.ime}
                </Text>
              </ListItem>
              <ListItem>
                <Text
                  as="span"
                  fontSize={{ base: "1em", md: "1.3em" }}
                  fontWeight="500"
                >
                  Prezime:{" "}
                </Text>
                <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                  {state.data.prezime}
                </Text>
              </ListItem>
              <ListItem>
                <Text
                  as="span"
                  fontSize={{ base: "1em", md: "1.3em" }}
                  fontWeight="500"
                >
                  Datum rođenja:{" "}
                </Text>
                <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                  {dayjs(state.data.datumRodenja).format("DD.MM.YYYY")}
                </Text>
              </ListItem>
              <ListItem>
                <Text
                  as="span"
                  fontSize={{ base: "1em", md: "1.3em" }}
                  fontWeight="500"
                >
                  Dob:{" "}
                </Text>
                <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                  {Math.abs(
                    dayjs(state.data.datumRodenja).diff(
                      dayjs(new Date()),
                      "year"
                    )
                  )}
                </Text>
              </ListItem>
              <ListItem>
                <Text
                  as="span"
                  fontSize={{ base: "1em", md: "1.3em" }}
                  fontWeight="500"
                >
                  Adresa:{" "}
                </Text>
                <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                  {state.data.ulica}, {state.data.postanskiBroj}{" "}
                  {state.data.grad}
                </Text>
              </ListItem>
              <ListItem>
                <Text
                  as="span"
                  fontSize={{ base: "1em", md: "1.3em" }}
                  fontWeight="500"
                >
                  OIB:{" "}
                </Text>
                <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                  {state.data.OIB}
                </Text>
              </ListItem>
              <ListItem>
                <Text
                  as="span"
                  fontSize={{ base: "1em", md: "1.3em" }}
                  fontWeight="500"
                >
                  Termin:{" "}
                </Text>
                <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                  {getTermin(state.data.datum, state.data.termin)}
                </Text>
              </ListItem>
              <ListItem>
                <Text
                  as="span"
                  fontSize={{ base: "1em", md: "1.3em" }}
                  fontWeight="500"
                >
                  Ambulanta:{" "}
                </Text>
                <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                  {ambulante[state.data.lokacija.value]}
                </Text>
              </ListItem>
              {state.data.nazivTvrtke && state.data.OIBTvrtke && (
                <>
                  <ListItem>
                    <Text
                      as="span"
                      fontSize={{ base: "1em", md: "1.3em" }}
                      fontWeight="500"
                    >
                      Naziv tvrtke:{" "}
                    </Text>
                    <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                      {state.data.nazivTvrtke}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text
                      as="span"
                      fontSize={{ base: "1em", md: "1.3em" }}
                      fontWeight="500"
                    >
                      OIB tvrtke:{" "}
                    </Text>
                    <Text as="span" fontSize={{ base: "1em", md: "1.3em" }}>
                      {state.data.OIBTvrtke}
                    </Text>
                  </ListItem>
                </>
              )}
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Zatvori
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default LastStep;
